import React from "react"
import * as styles from "./footer.module.css"
function Footer() {
  return (
    <div className={styles.footer}>
      <p>anuwataravis.dev</p>
    </div>
  )
}

export default Footer
