import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"

export default function Navbar() {
  const data = useStaticQuery(graphql`
    query SiteInfo {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <nav>
      <div>
        <Link to="/">
          <h1>anuwataravis</h1>
        </Link>
      </div>
      <div className="links">
        <Link to="/blog">Blog</Link>
        <a target="_blank" href="https://notes.anuwataravis.dev/">
          Notes
        </a>
      </div>
      <div>
        <a
          href="https://webring.wonderful.software#anuwataravis.dev"
          title="วงแหวนเว็บ"
        >
          <img
            alt="วงแหวนเว็บ"
            width="32"
            height="32"
            src="https://webring.wonderful.software/webring.black.svg"
          />
        </a>
      </div>
    </nav>
  )
}
